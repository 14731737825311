import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import RequestSendButton from '../../hooks/dall-e/requestsendbutton';
import ImageDisplay from '../../hooks/dall-e/imagedisplay';

interface DalleProps {
title: string;
roomType: string;
}

const Dalle: React.FC<DalleProps> = ({
title,
roomType
}) => {
const [text, setText] = useState<string>('');
const [prompt, setPrompt] = useState<string>('');
const [file, setFile] = useState<File | null>(null);
const [source, setSource] = useState<File | null>(null);
const [output, setOutput] = useState<React.ReactNode>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
/>

{apiKey && (
<>
<RequestSendButton
title={title}
roomType={roomType}
text={text}
setText={setText}
setPrompt={setPrompt}
file={file}
setFile={setFile}
setSource={setSource}
setOutput={setOutput}
isSending={isSending}
setIsSending={setIsSending}
/>

<ImageDisplay
roomType={roomType}
setText={setText}
prompt={prompt}
setPrompt={setPrompt}
setFile={setFile}
source={source}
setSource={setSource}
output={output}
setOutput={setOutput}
setIsSending={setIsSending}
apiKey={apiKey}
/>
</>
)}
</>
);
}

export default Dalle;
