import React, { useState } from 'react';

import { TransData } from '../../@types/data';

interface TransDataDisplayProps {
roomType: string;
transData: TransData[];
setTransDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
}

const TransDataDisplay: React.FC<TransDataDisplayProps> = ({
roomType,
transData,
setTransDataLoad,
setDeleteTarget
}) => {
const [visibleAnswerId, setVisibleAnswerId] = useState<number | null>(null);

const deleteDataHandler = (id: number) => {
setDeleteTarget(id);
};

const toggleAnswerVisibility = (id: number) => {
if (visibleAnswerId === id) {
setVisibleAnswerId(null); // 이미 보이는 답변을 클릭하면 숨깁니다.
} else {
setVisibleAnswerId(id); // 다른 답변을 클릭하면 해당 답변을 보여줍니다.
}
};

return (
<>
{transData.length > 0 && (
<>
<h1>{roomType === 'transcript' ? "기존 변환 기록" : "기존 번역 기록"}</h1>
<br />
</>
)}

{transData.map((item) => (
<React.Fragment key={item.id}>
{/* 요청 부분에 클릭 이벤트 핸들러를 추가하여 답변의 표시 여부를 토글하도록 합니다. */}
<h2 onClick={() => toggleAnswerVisibility(item.id)} style={{ cursor: 'pointer' }}>
{item.request}
</h2>
<br />
{/* 현재 보이는 답변의 ID와 아이템의 ID가 일치할 경우에만 답변을 표시합니다. */}
{visibleAnswerId === item.id &&
item.answerLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br />
</React.Fragment>
))}
<br /><br />
<button onClick={() => deleteDataHandler(item.id)}>삭제</button>
<br /><br /><br />
</React.Fragment>
))}
{setTransDataLoad(false)}
</>
);
};

export default TransDataDisplay;
