import React from 'react';

import { ImageItem } from '../../@types/item';
import { ImageBotResponse } from '../../@types/botresponse';

const createOutput = (
prompt: string,
botResponse: ImageBotResponse,
roomType: string
): JSX.Element => {
const headerContent = roomType === 'imagevariation' ? '변형 결과' : prompt.split('\n').map((line, index) => (
<React.Fragment key={index}>
{line}
<br/>
</React.Fragment>
));

const urls = botResponse.data?.map((item: ImageItem, index: number) => (
<div key={index}>
<a href={item.url} target="_blank" rel="noopener noreferrer">이미지 {index + 1}</a>
</div>
));

return (
<div>
<h2>{headerContent}</h2>
<br/>
{urls}
</div>
);
}

export default createOutput;
