import React from 'react';

import { ChatData } from '../../@types/data';

interface ChatDatasDeleteButtonProps {
setDeleteTargets: React.Dispatch<React.SetStateAction<string>>;
roomType: string;
chatData: ChatData[];
}

const ChatDatasDeleteButton: React.FC<ChatDatasDeleteButtonProps> = ({
setDeleteTargets,
roomType,
chatData
}) => {
const deleteButtonHandler = () => {
setDeleteTargets(roomType);
};

return (
<>
{chatData.length > 0 && (
<>
<button onClick={deleteButtonHandler}>전체 삭제</button>
<br/><br/>
</>
)}
</>
);
};

export default ChatDatasDeleteButton;
