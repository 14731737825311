import { TtsOption } from '../../@types/option';

export const voices: TtsOption[] = [
{ name: 'Alloy', value: 'alloy' },
{ name: 'Echo', value: 'echo' },
{ name: 'Fable', value: 'fable' },
{ name: 'Onyx', value: 'onyx' },
{ name: 'Nova', value: 'nova' },
{ name: 'Shimmer', value: 'shimmer' },
];
