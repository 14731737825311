import React from 'react';

import { TransData } from '../../@types/data';

interface TransDatasDeleteButtonProps {
setDeleteTargets: React.Dispatch<React.SetStateAction<string>>;
roomType: string;
transData: TransData[];
}

const TransDatasDeleteButton: React.FC<TransDatasDeleteButtonProps> = ({
setDeleteTargets,
roomType,
transData
}) => {
const deleteButtonHandler = () => {
setDeleteTargets(roomType);
};

return (
<>
{transData.length > 0 && (
<>
<button onClick={deleteButtonHandler}>전체 삭제</button>
<br/><br/>
</>
)}
</>
);
};

export default TransDatasDeleteButton;
