import React from 'react';

import Navigation from './navigations';

import './App.css';

const App: React.FC = () => {
return (
<>
<Navigation/>
</>
);
};

export default App;
