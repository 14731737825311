import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import { encodeToBase64 } from '../common/encode';
import { decodeToUtf8 } from '../common/decode';

import { ChatData } from '../../@types/data';
import { Item } from '../../@types/item';

interface ChatDataLoaderProps {
roomType: string;
deleteResult: string;
setDeleteResult: React.Dispatch<React.SetStateAction<string>>;
deleteResults: string;
chatDataLoad: boolean;
setChatData: React.Dispatch<React.SetStateAction<ChatData[]>>;
apiKey: string;
}

const ChatDataLoader: React.FC<ChatDataLoaderProps> = ({
roomType,
deleteResult,
setDeleteResult,
deleteResults,
chatDataLoad,
setChatData,
apiKey
}) => {
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string>('');

useEffect(() => {
const fetchData = async () => {
const encodedApiKey = encodeToBase64(apiKey);

try {
const response = await axios.post(
`${process.env.REACT_APP_API_URL}/getChatData`,
{
roomType: roomType,
apiKey: encodedApiKey
},
{
headers: {
'Content-Type': 'application/json',
},
}
);
const data = response.data;
const formattedData: ChatData[] = data.map((item: Item) => ({
id: item.id,
request: decodeToUtf8(item.request),
answer: decodeToUtf8(item.answer),
requestLines: decodeToUtf8(item.request).split('\n'),
answerLines: decodeToUtf8(item.answer).split('\n'),
}));

setChatData(formattedData);
setDeleteResult('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
} finally {
setLoading(false);
}
};

fetchData();
}, [roomType, deleteResult, setDeleteResult, deleteResults, chatDataLoad, setChatData, apiKey, setError]);

if (loading) {
return <div>Loading...</div>;
}

if (error) {
return (
<div className="error">
{error}
</div>
);
}

return null;
};

export default ChatDataLoader;
