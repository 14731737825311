import React, { useState, FC } from 'react';

import Header from '../../hooks/common/header';
import { ElevenLabsKey } from '../../hooks/common/key';
import TextSendButton from '../../hooks/eleventurbo/textsendbutton';
import LabsDisplay from '../../hooks/eleventurbo/labsdisplay';

interface EleventurboProps {
title: string;
roomType: string;
}

const Eleventurbo: FC<EleventurboProps> = ({
title,
roomType
}) => {
const [text, setText] = useState<string>('');
const [prompt, setPrompt] = useState<string>('');
const [voice, setVoice] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [xiApiKey, setXiApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<ElevenLabsKey
xiApiKey={xiApiKey}
setXiApiKey={setXiApiKey}
/>

{xiApiKey && (
<>
<TextSendButton
title={title}
text={text}
setText={setText}
setPrompt={setPrompt}
voice={voice}
setVoice={setVoice}
isSending={isSending}
setIsSending={setIsSending}
xiApiKey={xiApiKey}
/>

<LabsDisplay
roomType={roomType}
prompt={prompt}
setPrompt={setPrompt}
voice={voice}
setIsSending={setIsSending}
xiApiKey={xiApiKey}
/>
</>
)}
</>
);
}

export default Eleventurbo;
