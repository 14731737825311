import { useEffect } from 'react';

interface HeaderProps {
title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
useEffect(() => {
document.title = title;
}, [title]);

return (
<>
<h1><a href="/">AI</a></h1>
<br/>
</>
);
};

export default Header;
