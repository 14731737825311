import axios from 'axios';

export const axiosErrorHandler = (err: unknown, setError: (message: string) => void) => {
if (axios.isAxiosError(err) && err.response) {
setError(`Error: ${err.response.status} ${err.response.data}`);
} else if (err instanceof Error) {
setError(`Error: ${err.message}`);
} else {
setError("Error: The request was made but no response was received");
}

return null;
};
