import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';
import { encodeToBase64 } from '../common/encode';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';

import chatSound from '../../assets/sound/chat.wav';

interface ChatDisplayProps {
roomType: string;
setChatData: React.Dispatch<React.SetStateAction<ChatData[]>>;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
saveResult: string;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
isVoiceEnabled: boolean;
audioSrc: string;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
chatDataLoad: boolean;
apiKey: string;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
setChatData,
chatLog,
setChatLog,
model,
messages,
setMessages,
userInput,
setUserInput,
setFile,
saveResult,
setSaveResult,
setDeleteResults,
isVoiceEnabled,
audioSrc,
setAudioSrc,
setIsSending,
chatDataLoad,
apiKey
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
try {
const chatAudio = new Audio(chatSound);
chatAudio.play();
setError('');
} catch (err) {
setError("Error: Chat sound could not be played");
setUserInput('');
setIsSending(false);
return;
}

setDeleteResults('');
setSaveResult('');
setChatLog('잠시만 기다려 주세요.');

let data = {
model: model,
temperature: roomType === 'chat' ? 0.5 : 1.0,
top_p: roomType === 'chat' ? 0.5 : 0.9,
frequency_penalty: roomType === 'chat' ? 1.0 : 2.0,
presence_penalty: roomType === 'chat' ? 1.0 : 1.5,
max_tokens: 2048,
messages: messages,
};

const config = {
headers: {
Authorization: `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let promptTokens: number;
let completionTokens: number;
let totalTokens: number;
try {
const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.content;
promptTokens = response.data.usage.prompt_tokens;
completionTokens = response.data.usage.completion_tokens;
totalTokens = response.data.usage.total_tokens;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setIsSending(false);
return;
}

const encodedUserInput = encodeToBase64(userInput);
const encodedBotResponse = encodeToBase64(botResponse);
const encodedApiKey = encodeToBase64(apiKey);

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveChat`, {
userInput: encodedUserInput,
botResponse: encodedBotResponse,
roomType: roomType,
apiKey: encodedApiKey
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setIsSending(false);
return;
}

setChatLog(createChatLog(roomType, userInput, botResponse, promptTokens, completionTokens, totalTokens));
setMessages([...messages, { role: 'assistant', content: botResponse }]);
setUserInput('');

try {
const chatAudio = new Audio(chatSound);
chatAudio.play();
setFile(null);
setIsSending(false);
setError('');
if (!isVoiceEnabled) {
return;
}
} catch (err) {
setError("Error: Chat sound could not be played again");
setFile(null);
setIsSending(false);
return;
}

try {
const response = await axios.post(
'https://api.openai.com/v1/audio/speech',
{
model: "tts-1-hd",
voice: "nova",
input: botResponse
},
{
headers: {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
responseType: 'blob',
}
);

const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
setAudioSrc(audioUrl);
setFile(null);
setIsSending(false);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setFile(null);
setIsSending(false);
}
};

if (messages[messages.length - 1]?.role === 'user' && !chatDataLoad) {
SendRequest();
}
}, [roomType, setChatData, setChatLog, model, messages, setMessages, userInput, setUserInput, setFile, setSaveResult, setDeleteResults, isVoiceEnabled, setAudioSrc, setIsSending, chatDataLoad, apiKey, setError]);

return (
<>
{chatLog}
{error && (
<div className="error">
{error}
<br /><br />
</div>
)}
{saveResult}
{isVoiceEnabled && audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
</>
);
};

export default ChatDisplay;
