import React from 'react';

import { voices } from './voice';
import { keyDownHandler } from '../common/keydownhandler';

interface TextSendButtonProps {
title: string;
text: string;
setText: React.Dispatch<React.SetStateAction<string>>;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
voice: string;
setVoice: React.Dispatch<React.SetStateAction<string>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
}

const TextSendButton: React.FC<TextSendButtonProps> = ({
title,
text,
setText,
setPrompt,
voice,
setVoice,
isSending,
setIsSending
}) => {
const sendButtonHandler = () => {
if (text.trim() === '') {
alert('변환할 내용을 입력하세요.');
return;
}

setIsSending(true);
setPrompt(text);
};

return (
<>
<h1>{title}</h1>
<p>변환할 내용을 입력하세요.</p>
<p>변환할 내용</p>
<textarea
placeholder="변환할 내용"
value={text}
onChange={e => setText(e.target.value)}
onKeyDown={keyDownHandler}>
</textarea>
<br /><br />

<p>음성을 선택하세요.</p>
<p>음성 선택:</p>
{voices.map((voiceOption) => (
<label key={voiceOption.value}>
<input
type="radio"
name="voice"
value={voiceOption.value}
checked={voiceOption.value === voice}
onChange={(event) => setVoice(event.target.value)}
disabled={isSending}
/>
{voiceOption.name}
</label>
))}
<br /><br />

<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>음성 변환</button>
</>
);
};

export default TextSendButton;
