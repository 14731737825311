import React from 'react';

import { keyDownHandler } from '../common/keydownhandler';

interface TextSendButtonProps {
title: string;
text: string;
setText: React.Dispatch<React.SetStateAction<string>>;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
xiApiKey: string;
}

const TextSendButton: React.FC<TextSendButtonProps> = ({
title,
text,
setText,
setPrompt,
isSending,
setIsSending,
xiApiKey
}) => {

const sendButtonHandler = () => {
if (!text) {
alert('생성할 사운드에 대한 내용을 입력하세요.');
return;
}

setIsSending(true);
setPrompt(text);
};

return (
<>
<h1>{title}</h1>
<p>생성할 사운드에 대한 내용을 입력하세요.</p>
<p>생성할 사운드에 대한 내용</p>
<textarea
placeholder="생성할 사운드에 대한 내용"
value={text}
onChange={e => setText(e.target.value)}
onKeyDown={keyDownHandler}>
</textarea>
<br /><br />

<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>생성</button>
</>
);
}

export default TextSendButton;
