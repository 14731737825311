import { GptOption } from '../../@types/option';

export const languages: GptOption[] = [
{ name: '영어', value: '영어' },
{ name: '독일어', value: '독일어' },
{ name: '러시아어', value: '러시아어' },
{ name: '네덜란드어', value: '네덜란드어' },
{ name: '스페인어', value: '스페인어' },
{ name: '아랍어', value: '아랍어' },
{ name: '이탈리아어', value: '이탈리아어' },
{ name: '일본어', value: '일본어' },
{ name: '중국어 간체', value: '중국어 간체' },
{ name: '중국어 번체', value: '중국어 번체' },
{ name: '타갈로그어', value: '타갈로그어' },
{ name: '터키어', value: '터키어' },
{ name: '포르투갈어', value: '포르투갈어' },
{ name: '폴란드어', value: '폴란드어' },
{ name: '프랑스어', value: '프랑스어' },
{ name: '한국어', value: '한국어' },
{ name: '헝가리어', value: '헝가리어' },
{ name: '히브리어', value: '히브리어' },
];
