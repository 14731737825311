import React, { useState } from 'react';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';

interface ChatDataDisplayProps {
roomType: string;
chatData: ChatData[];
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
isSending: boolean;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
systemMessage: string;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
}

const ChatDataDisplay: React.FC<ChatDataDisplayProps> = ({
roomType,
chatData,
setDeleteTarget,
isSending,
setChatDataLoad,
systemMessage,
messages,
setMessages
}) => {
const [visibleAnswerId, setVisibleAnswerId] = useState<number | null>(null);
const [requestedIds, setRequestedIds] = useState<number[]>([]);

const registDataHandler = (request: string, answer: string, id: number) => {
setMessages([...messages,
{ role: 'user', content: request },
{ role: 'assistant', content: answer },
]);
setRequestedIds([...requestedIds, id]);
};

const deleteDataHandler = (id: number) => {
setDeleteTarget(id);
};

const initDataHandler = () => {
setMessages([
{ role: 'system', content: systemMessage },
]);
setRequestedIds([]);
alert('요청에서 삭제했습니다.');
};

const toggleAnswerVisibility = (id: number) => {
if (visibleAnswerId === id) {
setVisibleAnswerId(null);
} else {
setVisibleAnswerId(id);
}
};

return (
<>
{chatData.length > 0 && (
<>
<h1>기존 채팅 기록</h1>
<br />
</>
)}

{chatData.map((item) => (
<React.Fragment key={item.id}>
<h2 onClick={() => toggleAnswerVisibility(item.id)} style={{ cursor: 'pointer' }}>
{item.requestLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br />
</React.Fragment>
))}
</h2>
<br />
{visibleAnswerId === item.id &&
item.answerLines.map((line, index) => {
return (
<React.Fragment key={`answerLine-${index}`}>
{roomType === 'businessmail' ? (
<span dangerouslySetInnerHTML={{ __html: line.replace(/(['"])/g, '$1') }} />
) : (
<span>{line.replace(/(['"])/g, '$1')}</span>
)}
<br />
</React.Fragment>
);
})}
<br /><br />
<button
onClick={() => registDataHandler(item.request, item.answer, item.id)}
disabled={requestedIds.includes(item.id) || isSending || messages[messages.length - 1]?.role === 'user'}>
요청에 삽입
</button>
<br /><br />
<button onClick={() => deleteDataHandler(item.id)}>삭제</button>
<br /><br /><br /><br />
</React.Fragment>
))}
{chatData.length > 0 && (
<button
onClick={() => initDataHandler()}
disabled={requestedIds.length === 0 || isSending || messages[messages.length - 1]?.role === 'user'}>
요청 초기화
</button>
)}
{setChatDataLoad(false)}
</>
);
};

export default ChatDataDisplay;
